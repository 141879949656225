<template>
  <div>
    <div class="c_left">
      <div class="font24 color333 m-b-22">知识百科</div>
      <div
        class="c_left_btn"
        :class="curMenu == inx ? 'color-ffa weight mcl-ac' : ''"
        v-for="(item, inx) in navLis"
        :key="inx"
        @click="btn(menu[inx]?.path, (curMenu = inx))"
      >
        <!-- (curMenu = inx) -->
        <div class="aa hand flex-cen">
          <div class="m-r-5"><img :src="item.toolImg" alt="" /></div>
          <div>{{ item.toolName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toolApi } from "@/api/cyclo";
export default {
  data() {
    return {
      curMenu: "",
      navLis: [],
      menu: [
        { name: "", path: "/Cyclopedia/Edible" },
        { name: "", path: "/Cyclopedia/MeridianPoint" },
        { name: "", path: "/Cyclopedia/Prescription" },
        { name: "", path: "/Cyclopedia/Zwlz" },
        { name: "", path: "/Cyclopedia/Inquire" },
        { name: "", path: "/Cyclopedia/BringUp" },
        { name: "", path: "/Cyclopedia/Beauty" },
        { name: "", path: "/Cyclopedia/LoseWeight" },
      ],
    };
  },
  methods: {
    getNavList() {
      toolApi({
        token:
          "eyJ1c2VyX2lkIjoxLCJ1c2VyX25hbWUiOiJcdTdlZjRcdTVlYTZcdTZkNGJcdThiZDUxIiwibmFtZSI6Ilx1N2VmNFx1NWVhNlx1NmQ0Ylx1OGJkNVx1NTE2Y1x1NTNmOCIsInNvdXJjZV9pZCI6OCwibW9iaWxlIjoiMTMxMTMxMTMxMTMiLCJwcm92aW5jZSI6Ilx1NTZkYlx1NWRkZFx1NzcwMSIsImNpdHkiOiJcdTYyMTBcdTkwZmRcdTVlMDIiLCJkaXN0cmljdCI6Ilx1OTFkMVx1NzI1Ylx1NTMzYSIsImFkZHJlc3MiOiJcdTkxZDFcdTc5ZDFcdTUzMTdcdThkZWZcdTViODlcdTY4M2NcdTY1YWZcdTVjZjBcdTZjNDdcdTRlMmRcdTVmYzMiLCJrZXkiOiI1YmI1OWQ0YWFiMzllMGVjNWMzNzM1NzczZjJkMTg5YyJ9",
      }).then((res) => {
        this.navLis = res.data;
      });
    },
    btn(path, v) {
      console.log('v :>> ', v);
      // this.getNav = inx;
      this.$router.push({ path: path});
    },
  },
  created() {
    this.getNavList();
  },
};
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  .c_left {
    background: #f8f8f8;
    flex: 1;
    text-align: center;
    padding: 40px 0;
    .c_left_btn {
      line-height: 70px;
      &:hover .aa {
        background: #ffffff;
      }
      .mcl-ac::after {
        content: "";
        width: 40px;
        height: 4px;
        // background: #ff9905;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>