import http from "../utils/http"

// 经络穴位
export function toolApi(params) {
    return http.get(`/api/tool/tool`, params);
 }
// 方剂查询/中药查询
export function MedicineApi(params) {
    return http.get(`/api/tool/chineseMedicine`, params);
 }
// 工具文章详情
export function contentDetailsApi(params) {
    return http.get(`/api/tool/contentDetails`, params);
 }
// 食材药膳
export function medicineFoodApi(params) {
    return http.get(`/api/tool/medicineFood`, params);
 }
// 经络穴位
export function toolCateApi(params) {
    return http.get(`/api/tool/tool_cate`, params);
 }
// 中医育儿
export function medicineFindApi(params) {
    return http.get(`/api/tool/medicineFind`, params);
 }
// 子午
export function meridionalFlow(params) {
    return http.get(`/api/tool/meridionalFlow`, params);
 }